import React from 'react';
import Layout from '../../components/Layout';
import { StaticQuery, graphql } from 'gatsby';
import _ from 'lodash';
// import { Link } from 'gatsby';
// import get from 'lodash/get';
import Helmet from 'react-helmet';
import SEO from 'components/SEO/SEO';
// import querystring from 'querystring';
import logger from 'utils/logger';
import pluralize from 'pluralize';
import auth0Client from 'utils/Auth/Auth';

import { Container, Row, Col } from 'reactstrap';
import { Fluid, Offset } from 'components/Containers';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ProfileForm from 'components/Profile/ProfileForm';
import LoadingView from 'components/Loaders/LoadingView';

import { ExportToCsv } from 'export-to-csv';

import Header from 'components/Headers/Header';

class Template extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      currentCount: 60
    };
    this.handleCSV = this.handleCSV.bind(this);
  }

  handleCSV (parameters) {
    logger.verbose(`handleCSV(parameters)`, parameters)
    const date = new Date().toJSON().slice(0,10);
    const title = `${parameters.title}-CSV_export-${date}`
    const data = [];
    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: false,
      title: parameters.title,
      filename: title,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
    if (parameters.data) {
      if (parameters.data.length > 0) {
        parameters.data.forEach((edge) => {
          const { node } = edge;
          if (node.internal.type === `ContentfulProductSeries`) {
            const specifications = [];
            const productsIDs = [];
            const productsNames = [];

            if (node.specifications) {
              if (node.specifications.specifications) {
                if (node.specifications.specifications.length > 0) {
                  specifications.push(node.specifications.specifications);
                }
              }
            }
            if (node.products) {
              if (node.products.length > 0) {
                node.products.forEach((product) => {
                  if (product.specifications) {
                    if (product.specifications.specifications) {
                      if (product.specifications.specifications.length > 0) {
                        specifications.push(product.specifications.specifications);
                      }
                    }
                  }
                  productsIDs.push(product.id)
                  productsNames.push(product.name)
                });
              };
            }

            const specificationsFilter = _.uniqBy(specifications, `row`);

            let specificationsTable = `<table className="apantac-specifications-table table">`;

            if (specificationsFilter) {
              if (specificationsFilter.length > 0) {
                specificationsFilter.forEach((specification) => {
                  if (specification) {
                    if (specification.length) {
                      specification.forEach((row) => {
                        const rowHead = `<tr><th class="rowHead" style="font-weight: bold" colspan="2"><h2>${row.row}</h2></th></tr>`;
                        specificationsTable = specificationsTable + rowHead;
                        if (row.data) {
                          if (row.data.length > 0) {
                            row.data.forEach((data) => {
                              const string = `<tr><td class="key"><b>${data.key}</b></td><td class="value">${JSON.stringify(data.values).replace(/[\[\]'"]+/g, '').replace(/[,]+/g, ', ')}</td></tr>`;
                              specificationsTable = specificationsTable + string;
                            })
                          }
                        }
                      })
                    }
                  }
                })
              }
            }
            specificationsTable = specificationsTable + `</table>`;

            const images = [];
            if (node.slider) {
              if (node.slider.length > 0) {
                node.slider.forEach((slide) => {
                  if (slide.file) {
                    images.push(slide.file.url)
                  }
                })
              }
            }

            data.push({
              uuid: node.id,
              CMS_id: node.contentful_id,
              name: node.name,
              productLine: node.productLine ? node.productLine.name : ``,
              tagline: node.tagline,
              summary: node.summary ? node.summary.summary : ``,
              products_IDs: JSON.stringify(productsIDs).replace(/[\[\]'"]+/g, '').replace(/[,]+/g, ', '),
              products_names: JSON.stringify(productsNames).replace(/[\[\]'"]+/g, '').replace(/[,]+/g, ', '),
              thumbnail: node.thumbnail ? node.thumbnail.file.url : ``,
              images: JSON.stringify(images).replace(/[\[\]'"]+/g, '').replace(/[,]+/g, ', '),
              categories: JSON.stringify(node.fields.categories).replace(/[\[\]'"]+/g, '').replace(/[,]+/g, ', '),
              specifications: specifications.length > 0 ? specificationsTable : ``,
              specifications_JSON: specifications.length > 0 ? JSON.stringify(specificationsFilter[0]) : ``
            });
          }
          if (node.internal.type === `ContentfulArticle`) {
            data.push({
              uuid: node.id,
              CMS_id: node.contentful_id,
              title: node.title,
              excerpt: node.excerpt ? node.excerpt.excerpt : ``,
              content: node.content ? node.content.childMarkdownRemark.html : ``,
              categories: JSON.stringify(node.fields.categories).replace(/[\[\]'"]+/g, '').replace(/[,]+/g, ', '),
              thumbnail: node.thumbnail ? node.thumbnail.file.url : ``,
              createdAt: node.createdAtLegacy ? node.createdAtLegacy : node.createdAt
            });
          }
          if (node.internal.type === `ContentfulProduct`) {
            const specifications = [];

            if (node.specifications) {
              if (node.specifications.specifications) {
                if (node.specifications.specifications.length > 0) {
                  specifications.push(node.specifications.specifications);
                }
              }
            }

            const specificationsFilter = _.uniqBy(specifications, `row`);

            let specificationsTable = `<table className="apantac-specifications-table table">`;

            if (specificationsFilter) {
              if (specificationsFilter.length > 0) {
                specificationsFilter.forEach((specification) => {
                  if (specification) {
                    if (specification.length) {
                      specification.forEach((row) => {
                        const rowHead = `<tr><th class="rowHead" style="font-weight: bold" colspan="2"><h2>${row.row}</h2></th></tr>`;
                        specificationsTable = specificationsTable + rowHead;
                        if (row.data) {
                          if (row.data.length > 0) {
                            row.data.forEach((data) => {
                              const string = `<tr><td class="key"><b>${data.key}</b></td><td class="value">${JSON.stringify(data.values).replace(/[\[\]'"]+/g, '').replace(/[,]+/g, ', ')}</td></tr>`;
                              specificationsTable = specificationsTable + string;
                            })
                          }
                        }
                      })
                    }
                  }
                })
              }
            }
            specificationsTable = specificationsTable + `</table>`;

            const images = [];
            if (node.images) {
              if (node.images.length > 0) {
                node.images.forEach((slide) => {
                  if (slide.file) {
                    images.push(slide.file.url)
                  }
                })
              }
            }

            data.push({
              uuid: node.id,
              CMS_id: node.contentful_id,
              name: node.name,
              productLine: node.productLine ? node.productLine.name : ``,
              productSeries: node.product_series ? node.product_series[0].name : ``,
              tagline: node.tagline,
              summary: node.summary ? node.summary.summary : ``,
              thumbnail: node.thumbnail ? node.thumbnail.file.url : ``,
              images: JSON.stringify(images).replace(/[\[\]'"]+/g, '').replace(/[,]+/g, ', '),
              specifications: specifications.length > 0 ? specificationsTable : ``,
              specifications_JSON: specifications.length > 0 ? JSON.stringify(specificationsFilter[0]) : ``,
              youtube: node.youtube
            });
          }
        })
      }
    }
    logger.verbose(`options`, options);
    logger.verbose(`data`, data);

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  }

  render () {
    logger.template(__filename, '>', 'render()');
    const { data } = this.props;
    logger.verbose(`profile`);
    logger.gql(data);
    const user =  typeof localStorage !== 'undefined' && JSON.parse(localStorage.getItem('user'));
    logger.verbose(`user`, user);

/*
    let downloadType = downloadFile.type;
    if (downloadType === `document`) {
      downloadType = `support`;
    }
    if (downloadFile.accessLevel && !downloadFile.accessLevel.includes(`public`)) {
      let hasAccess = false;
      if (user) {
        if (user.permissions && user.permissions.groups) {
          user.permissions.groups.forEach((group) => {
            logger.verbose(`user.permissions.groups.forEach((group)`, group);
            if (downloadFile.accessLevel.includes(group)) {
              hasAccess = true;
            }
          });
        }
      }
      if (!hasAccess) {
        logger.error(`Download unauthorized`);
        navigate(`unauthorized`);
      } else {
        logger.verbose(`Download authorized`);
      }
    }
 */

    const headerProps = {
      height: `8vh`,
      bgColor: `indigo-500`,
      // bgImage: `/img/Universiade-2017.jpg`,
      // parallax: [
      //   {
      //     image: '/img/Universiade-2017.jpg',
      //     amount: 0.35,
      //     slowerScrollRate: false
      //   }
      // ]
    };
    if (!user) {
      return <LoadingView />;
    }
    const pricesLists = [];
    const resources = [];

    data.allContentfulPrivateResource.edges.forEach((edge) => {
      const resource = edge.node;
      const access = user.permissions && user.permissions.groups.some(r=> resource.accessLevel.includes(r));
      if (access) {
        if (resource.type === `prices-list`) {
          pricesLists.push(resource);
        }
        else {
          resources.push(resource);
        }
      }
    });
    const accessCSV = user.permissions && user.permissions.groups.includes(`csv-exports`);
    logger.debug(`accessCSV`, accessCSV);

    const productSeries = data.allContentfulProductSeries.edges;
    const articles = data.allContentfulArticle.edges;
    const products = data.allContentfulProduct.edges;

    logger.templateEnd();
    return (
      <Layout>
        <Fluid>
          <SEO title={`My Account`} />
          <Header {... headerProps}>
            <Offset className={`text-left`}>
              <h1 className={`fs-15em fw-400`}>My Apantac {user.given_name && (<frag> | <span className="fw-300">{user.given_name}</span></frag>)}</h1>
            </Offset>
          </Header>
          <Fluid>
            <Offset>
              <Tabs>
                <TabList>
                  <Tab>My Account</Tab>
                  {pricesLists.length > 0 && ( <Tab>Price Lists</Tab> )}
                  {resources.length > 0 && ( <Tab>Private Resources</Tab> )}
                  {/*
                    Future feature - MY PRODUCTS
                  <Tab>My Products</Tab>
                  */}
                  {/*
                    Future feature - MY TICKETS
                  <Tab>My Tickets</Tab>
                  */}
                  {accessCSV && ( <Tab>Data Exports</Tab> )}
                </TabList>
                <TabPanel>
                  <Container fluid className={`prl-0`}>
                    <Row>
                      <Col>
                        <div className="content ptb-20em">
                          <h1 className={`fw-500 mb-0`}>
                            <span className={`mr-5em`}><img src={user.picture} className="mx-auto img-fluid rounded img-circle d-inline-block" alt="avatar" style={{ width: `100%`, maxWidth: `64px` }} /></span>
                            {user.name}
                          </h1>
                          <p className="lead">{user.email}</p>
                          <hr />
                          <ProfileForm />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </TabPanel>
                {pricesLists.length > 0 && (
                  <TabPanel>
                    <Container fluid className={`prl-0`}>
                      <Row>
                        <Col>
                          <div className="content ptb-20em">
                            <h1 className={`fw-500 mb-0`}>
                              Price Lists
                            </h1>
                            <hr />
                            <div>
                              {
                                pricesLists.map((resource) => {
                                  return (
                                    resource && (
                                      <div key={`resource-${resource.id}`}>
                                        <h4>{resource.name}</h4>
                                      </div>
                                    )
                                  );
                                })
                              }
                              {/*<p>Price lists available for specific user access level (group or role)...</p>*/}
                              {/*<p>No price lists found</p>*/}

                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </TabPanel>
                )}
                {resources.length > 0 && (
                  <TabPanel>
                    <Container fluid className={`prl-0`}>
                      <Row>
                        <Col>
                          <div className="content ptb-20em">
                            <h1 className={`fw-500 mb-0`}>
                              Private Resources
                            </h1>
                            <hr />
                            <div>
                              <p>No private resources found</p>
                              {/*<p>Private resources available for specific user access level (group or role)...</p>*/}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </TabPanel>
                )}
                {/*
                    Future feature - MY PRODUCTS
                <TabPanel>
                  <Container fluid className={`prl-0`}>
                    <Row>
                      <Col>
                        <div className="content ptb-20em">
                          <h1 className={`fw-500 mb-0`}>
                            My Products
                          </h1>
                          <hr />
                          <div>
                            <p><b>Possible Future Feature</b></p>
                            <p>User list of products and form to register new products</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </TabPanel>
                */}
                {/*
                    Future feature - MY TICKETS
                <TabPanel>
                  <Container fluid className={`prl-0`}>
                    <Row>
                      <Col>
                        <div className="content ptb-20em">
                          <h1 className={`fw-500 mb-0`}>
                            My Tickets
                          </h1>
                          <hr />
                          <div>
                            <p><b>Possible Future Feature</b></p>
                            <p>Instead of using an externla window, the support experience should remain in the website and any ticket should be stored and related to a registered user.</p>
                            <p>The current ticket system (osTicket), has a very limite API which according to their documentation only supports ticket creation for now.</p>
                            <p><a href="https://docs.osticket.com/en/latest/Developer%20Documentation/API%20Docs.html#" target={`_blank`}>https://docs.osticket.com/en/latest/Developer%20Documentation/API%20Docs.html#</a></p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </TabPanel>
                */}
                {accessCSV && (
                  <TabPanel>
                    <Container fluid className={`prl-0`}>
                      <Row>
                        <Col>
                          <div className="content pt-20em pb-40em">
                            <h1 className={`fw-500 mb-0`}>
                              Data Export Tool
                            </h1>
                            <hr />
                            <div>
                              <p>Select the type of content you want to export. Export will not include EOL or Draft entries, only public and published entries.</p>
                            </div>
                            <div className={`pb-5em`}>
                              <hr />
                              <h2 className={`fw-400 mb-0`}>Product Series</h2>
                              <p className="fs-085em">Export Product Series to: CSV File - {productSeries.length} entries</p>
                              <button
                                onClick={() => this.handleCSV({
                                  data: productSeries,
                                  title: `Apantac-ProductSeries`
                                })}
                                className={`btn btn-outline-indigo-300 ls-400em color-indigo-500 color-indigo-600-hover ptb-12em pl-25em pr-20em`}
                              >
                                Export Data
                              </button>
                            </div>
                            <div className={`pb-5em`}>
                              <hr />
                              <h2 className={`fw-400 mb-0`}>Products</h2>
                              <p className="fs-085em">Export Products to: CSV File - {products.length} entries</p>
                              <button
                                onClick={() => this.handleCSV({
                                  data: products,
                                  title: `Apantac-Products`
                                })}
                                className={`btn btn-outline-indigo-300 ls-400em color-indigo-500 color-indigo-600-hover ptb-12em pl-25em pr-20em`}
                              >
                                Export Data
                              </button>
                            </div>
                            <div className={`pb-5em`}>
                              <hr />
                              <h2 className={`fw-400 mb-0`}>Articles</h2>
                              <p className="fs-085em">Export Articles to: CSV File - {articles.length} entries</p>
                              <button
                                onClick={() => this.handleCSV({
                                  data: articles,
                                  title: `Apantac-Articles`
                                })}
                                className={`btn btn-outline-indigo-300 ls-400em color-indigo-500 color-indigo-600-hover ptb-12em pl-25em pr-20em`}
                              >
                                Export Data
                              </button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </TabPanel>
                )}
              </Tabs>
            </Offset>
          </Fluid>
        </Fluid>
      </Layout>
    );
  }
}

// export default Template;

// export const profileQuery = graphql`
//   query ProfilesQuery {
//   }
// `;

const query = graphql`
  query {
    allContentfulPrivateResource{
      edges{
        node{
          id
          name
          type
          accessLevel
          resource {
            id
            file{
              url
              fileName
              contentType
              details {
                size
                image {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
    allContentfulProductSeries(filter: {eol: {ne: true}}){
      edges{
        node{
          id
          internal {
            type
          }
          thumbnail{
            file{
              url
            }
          }
          slider{
            file{
              url
            }
          }
          contentful_id
          name
          productLine{
            name
          }
          tagline
          summary{
            summary
          }
          fields {
            categories
          }
          products{
            name
            id
            contentful_id
            tagline
            description {
              childMarkdownRemark {
                html
              }
            }
            specifications {
              specifications {
                row
                data {
                  key
                  values
                }
              }
            }
          }
          specifications{
            specifications{
              row
              data {
                key
                values
              }
            }
          }
        }
      }
    }
    allContentfulArticle(sort: { fields: [createdAtLegacy], order: DESC}){
      edges{
        node {
          contentful_id
          id
          internal {
            type
          }
          ... Article
          thumbnail{
            file{
              url
            }
          }
          fields {
            categories
          }
        }
      }
    }
    allContentfulProduct(filter: {eol: {ne: true}}){
      edges{
        node{
          contentful_id
          id
          name
          internal {
            type
          }
          productLine{
            name
          }
          product_series{
            name
          }
          thumbnail{
            file{
              url
            }
          }
          images{
            file{
              url
            }
          }
          summary{
            summary
          }
          tagline
          description{
            childMarkdownRemark{
              html
            }
          }
          youtube
          specifications{
            specifications{
              row
              data{
                key
                values
              }
            }
          }
        }
      }
    }
  }
`;

export default ({ children }) => (
  <StaticQuery
    query={query}
    render={data => (
      <>
        <Helmet title={`${`About Us`}`} />
        <Template data={data}>
          {children}
        </Template>
      </>
    )}
  />
);


