import React from 'react';
import logger from 'utils/logger';
import AutoForm from 'uniforms-material/AutoForm';
import TextField from 'uniforms-material/TextField';
import LongTextField from 'uniforms-material/LongTextField';
// import BoolField from 'uniforms-material/BoolField';
import SelectField from 'uniforms-material/SelectField';
import HiddenField from 'uniforms-unstyled/HiddenField';
import SubmitField from 'uniforms-unstyled/SubmitField';
import ErrorsField from 'uniforms-material/ErrorsField'; // Choose your theme package.
import { ContactFormSchema } from 'api/schemas/ContactFormSchema';
import { AUTH_CONFIG } from 'utils/Auth/auth0-variables';
import axios from 'axios';
import auth0 from 'auth0-js';

// import { TextFieldDark } from 'layouts/jss';
import { Container, Row, Col } from 'reactstrap';

const formName = `General Contact`;
const formLabel = `Contact`;
const formSchema = ContactFormSchema;

const webAuth = new auth0.WebAuth({
  clientID: AUTH_CONFIG.clientId,
  domain: AUTH_CONFIG.domain,
  audience: `https://${AUTH_CONFIG.domain}/api/v2/`,
  scope: 'read:current_user update:current_user update:current_user_metadata',
  responseType: 'token id_token'
});

export default class Component extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      form: {},
      error: false,
      success: false,
      updateProfile: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
  }

  updateProfile () {
    this.setState({
      updateProfile: true
    });
  }

  handleChange (key, value) {
    logger.info('key', key, 'value', value);
    logger.verbose(`this`, this);
    let mutateForm = this.state.form;
    mutateForm[key] = value;

    this.setState({
      form: mutateForm
    });
  }

  handleSubmit (doc) {
    logger.group(__filename, '>', ' handleSubmit (doc)');
    logger.verbose(`this`, this);
    const body = {
      user_metadata: {
        addresses: {
          home: `123 Main Street, Anytown, ST 12345 v2`
        }
      }
    };

    if (typeof window !== 'undefined') {
      logger.request(`patchUser(body)`, body);
      const accessToken = localStorage.getItem('access_token');
      const user = JSON.parse(localStorage.getItem('user'));
      const userId = user.sub;
      if (accessToken && userId) {
        const bodyParse = JSON.stringify(body);
        const endpoint = `https://${AUTH_CONFIG.domain}/api/v2/users/${userId}`;

        const auth0Manage = new auth0.Management({
          domain: AUTH_CONFIG.domain,
          token: accessToken
        }, (err, res) => {
          if (err) {
            // throw new Error(err);
            logger.error(`auth0Manage err`, err);
          }
          logger.response(`auth0Manage res`, res);
          return res;
        });
        logger.debug(`auth0Manage`, auth0Manage);
        const auth0ManageToken = auth0Manage.baseOptions.token;
        logger.debug(`auth0ManageToken`, auth0ManageToken);

        const headers = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth0ManageToken}`
          }
        };
        logger.verbose(`user`, user);
        logger.verbose(`userId`, userId);
        logger.verbose(`endpoint`, endpoint);
        logger.verbose(`headers`, headers);
        logger.verbose(`body`, body);
        axios.patch(endpoint, bodyParse, headers)
          .then((response) => {
            localStorage.setItem('profile', JSON.stringify(response.data));
          })
          .catch((error) => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              logger.error(error.response.data);
              logger.error(error.response.status);
              logger.error(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              logger.error(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              logger.error('Error', error.message);
            }
            logger.error(error.config);
          });
      } else {
        // handle not logged in
        throw (`User not logged!`);
      }
    }

    logger.verbose(`this.state`, this.state);
    logger.groupEnd();
  }

  render () {
    logger.file(__filename, '>', 'render()');
    logger.verbose('formSchema', formSchema);
    const user =  typeof localStorage !== 'undefined' && JSON.parse(localStorage.getItem('user'));
    logger.verbose('user', user);
    const authorization = user[`http://localhost:8000/`];
    const groups = authorization.groups;

    logger.fileEnd();
    logger.info(`this.state:`, this.state);

    return (
      <Container className={`prl-0`}>
        {!this.state.updateProfile ? (
          <div>
            <div className={`mb-20em`}>
              <h3 className={`ls--1px fw-400`}>Account Details</h3><hr />
              <div>
                <p><b>Company:</b> {user.user_metadata && user.user_metadata.company}</p>
                <p><b>Name:</b> {user.name}</p>
                <p><b>Job Title:</b> {user.user_metadata && user.user_metadata.jobTitle}</p>
                <p>
                  <b>Accunt Type:</b> {groups > 0 ? (
                    groups.map((group) => {
                      return pluralize.singular(group);
                    })
                  ) : (
                    <span>User</span>
                  )}
                </p>
              </div>
            </div>
            <div className={`mb-20em`}>
              <h3 className={`ls--1px fw-400`}>Contact information</h3><hr />
              <div>
                <p><b>Email:</b> {user.email}</p>
                <p><b>Phone Number:</b> {user.user_metadata && user.user_metadata.name}</p>
                <p><b>Fax:</b> {user.user_metadata && user.user_metadata.name}</p>
                <p><b>LinkedIn:</b> {user.user_metadata && user.user_metadata.name}</p>
                <p><b>Address:</b> {user.user_metadata && user.user_metadata.address_address_1}</p>
              </div>
            </div>
            <div className={`mb-20em`}>
              <h3 className={`ls--1px fw-400`}>Company information</h3><hr />
              <div>
                <p><b>Market:</b> {user.user_metadata && user.user_metadata.company}</p>
                <p><b>Website:</b> {user.user_metadata && user.user_metadata.website}</p>
                <p><b>LinkedIn:</b> {user.user_metadata && user.user_metadata.company}</p>
                <p><b>YouTube:</b> {user.user_metadata && user.user_metadata.company}</p>
                <p><b>Twitter:</b> {user.user_metadata && user.user_metadata.company}</p>
                <p><b>Facebook:</b> {user.user_metadata && user.user_metadata.company}</p>
              </div>
            </div>
            <hr />
            <div>
              <button className={`btn btn-outline-gray-warm-800`} onClick={this.updateProfile}>Update Information</button>
            </div>
          </div>
        ) : (
          <AutoForm
            schema={formSchema}
            showInlineError
            validate="onChangeAfterSubmit"
            onChange={(key, value) => {
              this.handleChange(key, value);
            }}
            onSubmit={this.handleSubmit}

            name={formName}
            method="post"
            data-netlify="true"
            data-netlify-honeypot="botField"
            data-subject={`${formName} ${this.state.form.subject} from <${this.state.form.email}>`}
          >
            <Row>
              <Col xs={12}>
                <h1 className={`color-gray-cold-800`}>{formLabel}</h1>
                <hr />
              </Col>
            </Row>
            <Row className={`mb-20em`}>
              <Col xs={12}><h2 className={`fs-12em mt-05em fw-400 mb-0`}>Contact Information</h2></Col>
              <Col md={6} lg={4}><TextField className={`mui`} name="name" /></Col>
              <Col md={6} lg={4}><TextField className={`mui`} name="email" /></Col>
            </Row>
            <Row className={`mb-20em`}>
              <Col xs={12}><h2 className={`fs-12em mt-05em fw-400 mb-0`}>Company Details</h2></Col>
              <Col md={6} lg={4}><TextField className={`mui`} name="company" /></Col>
              <Col md={6} lg={4}><TextField className={`mui`} name="jobTitle" /></Col>
            </Row>
            <Row className={`mb-10em`}>
              <Col xs={12}><h2 className={`fs-12em mt-05em fw-400 mb-0`}>Inquiry Details</h2></Col>
              <Col lg={8}><SelectField className={`mui select`} name="subject" /><input type="hidden" style={{ display: `none`, visibility: `hidden` }} name="subject" value={this.state.subject} /></Col>
              <Col lg={8}><LongTextField className={`mui`} name="inquiry" rows={4} rowsMax={6} /></Col>
            </Row>
            <Row>
              <Col xs={12}>
                {this.state.error && <div className={`mb-20em fs-09em`}>There was an error processing your message, please verify the information or try again latter.</div>}
                <ErrorsField />
              </Col>
            </Row>
            <Row className={`mb-10em`}>
              <Col md={6} lg={4}>
                <HiddenField name="botField" />
                <SubmitField
                  value="Send"
                  className={`btn btn-primary-500 btn-md`}
                />
              </Col>
            </Row>
          </AutoForm>
        )}
      </Container>
    );
  }
}
